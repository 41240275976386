
export default {
    //命名空间
    namespace: "wechat",
    //仓库初始化状态
    state: {
        notifyId: ''
    },
    reducers: {
        updateReducers(state, { type, payload }) {
            return {
                ...state,
                [payload.key]: payload.val
            }
        }
    },
    effects: {
        //页面接口
        *getNotifyId(action, { put }) {
            const result = action.payload
            yield put({ type: 'updateReducers', payload: { key: 'notifyId', val: result.notifyId } })
        },
    }
};
