//项目入口文件
import dva from "dva";
import { persistEnhancer } from 'dva-model-persist';
import router from "@/router";
import history from "@/utils/history";
import {createModel} from "@/store";
import createLoading from 'dva-loading';
import { message } from 'antd';
import "@/assets/css/mixin.scss";
import "@/assets/css/style.scss";
import "@/assets/css/common.scss";
import "@/assets/css/iconfont.scss";
const NODE_ENV = process.env.NODE_ENV;
if (NODE_ENV === "development") {
    // window.API_HOST = "http://192.168.8.118:12003/service";
    window.API_HOST = "http://stock-test.qihuian.com/service";
    window.IMAGE_HOST = "http://stock-test.qihuian.com";
    // window.API_HOST = "https://scf.qihuian.com/service";
    // window.IMAGE_HOST = "http://stock-dev.hongxindagroup.com";
    // window.API_HOST = "http://stock-dev.hongxindagroup.com/service";
    //  window.API_HOST = "https://stock.hongxindagroup.com/service";
    // window.IMAGE_HOST = "https://stock.hongxindagroup.com";

} else {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
    window.API_HOST = "https://stock.hongxindagroup.com/service";
    window.IMAGE_HOST = "https://stock.hongxindagroup.com";
}
const app = dva({
    history: history,
    onError(e) {
        message.error(e.message);
    },
    onStateChange(state){
        // console.log("dva里面的值："+ JSON.stringify(state))
    }
    // onReducer(reducer){
    //     const persistConfig = {
    //         key: 'root',
    //         storage,
    //     };
    //     return persistReducer(persistConfig, reducer);
    // }
});
createModel(app);
app.router(router);
app.use(createLoading());
app.use({
    extraEnhancers: [
        persistEnhancer()
    ],
});
app.start("#root");