import axios from 'axios';
import qs from 'qs';

const http = axios.create({
	headers: {"pragma": "no-cache"},//请求头,

});
export const unCrudeHttp = http;

// 添加一个请求拦截器
http.interceptors.request.use(config => {
	config.headers['Authorization'] = localStorage.getItem('tokens');
	config.url = config.url.includes('?') ? config.url + '&_t=' + new Date().getTime() : config.url + '?_t=' + new Date().getTime();
	return config;
}, error => {
	return Promise.reject(error);
});

//响应拦截器
//3001020114-token:刷新token,半个小时(保证用户操作时，token永不失效)
//3001020111-token-有效期一个小时,失效
//3001020112-没有授权信息
//3001020113-用户在其它地方登陆
http.interceptors.response.use(response => {
	if (response.data.code === "3001020114") {
		localStorage.setItem('tokens', response.data.data.token);
		let originalRequest = response.config;
		return http.request(originalRequest);
	} else if (response.data.code === '401' || response.data.code === "3001020111" || response.data.code === "3001020112" || response.data.code === "3001020113") {
		localStorage.clear();
		sessionStorage.clear();
		setTimeout(() => {
			window.location.href = "/login";
		}, 1000);
		response.data.data = {};
		return response;
	} else {
		return response;
	}
}, error => {
	return Promise.reject({code: '0000', message: '服务器连接失败'});
});

function getBaseUrl(url, is) {
	// if (is === "api") {
	// 	return `/api${url}`
	// } else if (is === "apc") {
	// 	return `/apc${url}`
	// } else if (is === "ap2") {
	// 	return `/ap2${url}`
	// } else if (is === "ap3") {
	// 	return `/ap3${url}`
	// }
	return `${window.API_HOST}${url}`;
}

//封装post方法
export function post(relateUrl, params = {}, isJson = true, timeout = 50000, is = "api") {
	return new Promise((resolve, reject) => {
		http.post(getBaseUrl(relateUrl, is), isJson === true ? params : qs.stringify(params), {
			timeout: timeout
		}).then(res => {
			if (res.data.code === "2000") {
				resolve(res.data)
			} else {
				reject({message: "[" + res.data.code + "]" + res.data.message});
			}
		}).catch(err => {
			if (typeof err == 'string') {
				reject({message: err});
			} else if (err.code !== "2000" || err.code === "0001") {
				reject({message: "[" + err.code + "]" + err.message});
			}
			let error;
			if (err.response) {
				// 请求已发出，但服务器响应的状态码不在 2xx 范围内
				error = err.response.data
			} else {
				// Something happened in setting up the request that triggered an Error
				error = "[" + err.code + "]" + err.message
			}
			reject(error);
		})
	});
}

//封装get方法
export function get(relateUrl, params = {}, isJson = true, timeout = 50000, is = "api") {
	return new Promise((resolve, reject) => {
		http.get(getBaseUrl(relateUrl, is), {
			timeout: timeout
		}).then(res => {
			if (res.data.code === "2000") {
				resolve(res.data)
			} else {
				reject({message: "[" + res.data.code + "]" + res.data.message});
			}
		}).catch(err => {
			if (typeof err == 'string') {
				reject({message: err});
			} else if (err.code !== "2000" || err.code === "0001") {
				reject({message: "[" + err.code + "]" + err.message});
			}
			let error;
			if (err.response) {
				// 请求已发出，但服务器响应的状态码不在 2xx 范围内
				error = err.response.data
			} else {
				// Something happened in setting up the request that triggered an Error
				error = "[" + err.code + "]" + err.message
			}
			reject(error);
		})
	});
}

//封装put方法
export function put(relateUrl, params = {}, isJson = true, timeout = 50000, is = "api") {
	return new Promise((resolve, reject) => {
		http.put(getBaseUrl(relateUrl, is), params).then(res => {
			if (res.data.code === "2000") {
				resolve(res.data)
			} else {
				reject({message: "[" + res.data.code + "]" + res.data.message});
			}
		}).catch(err => {
			if (typeof err == 'string') {
				reject({message: err});
			} else if (err.code !== "2000" || err.code === "0001") {
				reject({message: "[" + err.code + "]" + err.message});
			}
			let error;
			if (err.response) {
				// 请求已发出，但服务器响应的状态码不在 2xx 范围内
				error = err.response.data
			} else {
				// Something happened in setting up the request that triggered an Error
				error = "[" + err.code + "]" + err.message
			}
			reject(error);
		})
	});
}